import { useState } from "react";
import Loader from "../Loader";
import { getStyledIcon } from "@litonarefin/utils/getStyledIcon";
import { getIcon } from "@litonarefin/utils/icons";
// import { Turnstile } from "@marsidev/react-turnstile";
import Link from "next/link";
import { handleToastr } from "@litonarefin/utils/handleToastr";
import { signIn } from "next-auth/react";

export const LoginPopup = ({ isRegister = false, reload = true }) => {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [userInfo, setUserInfo] = useState({
        user_email: "",
        user_pass: "",
        remember_me: 0,
    });

    const handleOnchange = (e) => {
        let type = e.target.type;
        let name = e.target.name;
        let value = e.target.value;
        if (type == "checkbox") {
            value = !userInfo.remember_me;
        }
        setUserInfo({ ...userInfo, [name]: value });
    };

    const handleLogin = async (e) => {
        setLoading(true);

        try {
            const res = await signIn("credentials", {
                username: userInfo?.user_email,
                password: userInfo?.user_pass,
                rememberMe: userInfo?.remember_me,
                redirect: false,
            });

            if (res.error) {
                handleToastr("Invalid Credentials", "error");
                setLoading(false);
                return;
            }

            setLoading(false);
            if (!!reload) {
                window.location.reload();
            }
            // router.replace("/dashboard/orders");
            handleToastr("Login Successfull!", "success");
        } catch (error) {
            handleToastr("Something Went Wrong, Please try again!", "error", 3000);
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     if (session?.user?.id) {
    //         // localStorage.setItem(`${siteSource}AuthUserId`, session?.user?.id);
    //         // localStorage.setItem(`${siteSource}AuthUserName`, session?.user?.username);
    //         // localStorage.setItem(`${siteSource}AuthUserEmail`, session?.user?.email);
    //         // localStorage.setItem(`${siteSource}AuthUserDisplayName`, session?.user?.displayName);
    //         // localStorage.setItem(`${siteSource}AuthUserAvatar`, session?.user?.avatarUrl);
    //     }
    // }, [session?.user?.id]);

    return (
        <div className="jlt-p-6 md:jlt-p-8 jlt-bg-white jlt-shadow-[0px_0px_35px_rgba(173,173,188,0.1)] jlt-rounded-lg">
            <h5 className="jlt-text-[#16213E] jlt-text-center">Login</h5>
            <div className="jlt-mt-8">
                <form>
                    <div>
                        <label
                            htmlFor="emailAddress"
                            className="jlt-text-[#475467] jlt-font-semibold jlt-leading-6">
                            Username or Email*
                        </label>
                        <input
                            type="text"
                            name="user_email"
                            onChange={handleOnchange}
                            value={userInfo.user_email}
                            className="jlt-w-full jlt-mt-2 jlt-px-4 jlt-py-3 jlt-bg-[#FCFDFF] jlt-rounded jlt-border jlt-border-[#EBEEF5] jlt-outline-none"
                            id="emailAddress"
                            placeholder="Username or Email"
                        />
                    </div>
                    <div className="jlt-mt-5">
                        <label
                            htmlFor="password"
                            className="jlt-text-[#475467] jlt-font-semibold jlt-leading-6">
                            Password*
                        </label>
                        <div className="jlt-relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="user_pass"
                                onChange={handleOnchange}
                                value={userInfo.user_pass}
                                className="jlt-w-full jlt-mt-2 jlt-px-4 jlt-py-3 jlt-bg-[#FCFDFF] jlt-rounded jlt-border jlt-border-[#EBEEF5] jlt-outline-none"
                                placeholder="*********"
                            />
                            <div
                                onClick={() => setShowPassword(!showPassword)}
                                className="jlt-absolute jlt-top-6 jlt-right-[18px] jlt-cursor-pointer jlt-flex jlt-items-center jlt-justify-center">
                                {showPassword
                                    ? getStyledIcon(getIcon("openEye"), "#475467", "stroke")
                                    : getStyledIcon(getIcon("closeEye"), "#475467", "stroke")}
                            </div>
                        </div>
                    </div>
                    <div className="jlt-my-4">
                        {/* <Turnstile
                            style={{ width: "100%" }}
                            siteKey={process.env.NEXT_PUBLIC_CLOUDFLARE_TRUNSTILE_KEY}
                        /> */}
                    </div>
                    <div className="jlt-flex jlt-justify-between jlt-items-center">
                        <div className="jlt-flex jlt-gap-2 jlt-items-center">
                            <input
                                type="checkbox"
                                name="remember_me"
                                onChange={handleOnchange}
                                className="jlt-w-4 jlt-h-4 jlt-border-[1px] jlt-border-[#B4BBCB] jlt-rounded-sm jlt-cursor-pointer"
                                id="exampleCheck2"
                            />
                            <label
                                className="jlt-text-sm jlt-font-semibold jlt-text-[#72798A] jlt-cursor-pointer"
                                htmlFor="exampleCheck2">
                                Remember me
                            </label>
                        </div>
                        <Link
                            href="/account/reset"
                            className="jlt-text-sm jlt-text-[#256EFF] jlt-font-bold jlt-cursor-pointer">
                            Lost your password?
                        </Link>
                    </div>
                    <div className="jlt-mt-8">
                        <button
                            type="button"
                            onClick={handleLogin}
                            className="jlt-px-4 jlt-py-[10px] jlt-w-full jlt-bg-[#256EFF] jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-full jlt-flex jlt-gap-2 jlt-items-center jlt-justify-center">
                            {loading && (
                                <div className="action-loader">
                                    <Loader type={"button-loader"} />
                                </div>
                            )}
                            <span className="jlt-text-white jlt-text-sm jlt-font-semibold">
                                Log in
                            </span>
                            <svg
                                width={17}
                                height={8}
                                viewBox="0 0 17 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                        {!!isRegister ? (
                            <>
                                <hr className="jlt-w-full jlt-my-4" />
                                <p className="jlt-text-center jlt-font-semibold">
                                    <span>Don't have an account?</span>{" "}
                                    <Link
                                        href="/account/register"
                                        className="jlt-text-[#256EFF] jlt-cursor-pointer">
                                        Register Now
                                    </Link>
                                </p>
                            </>
                        ) : null}
                    </div>
                </form>
            </div>
        </div>
    );
};
