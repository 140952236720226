"use client";

// import styled from "styled-components";
import { isEmpty } from "lodash";

export const JSToCSS = (JS) => {
    let cssStr = "";
    for (let objKey in JS) {
        cssStr +=
            objKey.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`) + ": " + JS[objKey] + ";\n";
    }

    return cssStr;
};

function StyledIcon({
    children,
    color = "#3D79F2",
    type = "fill",
    styles = undefined,
    parentStyles = undefined,
}) {
    return (
        <span className="jlt_svg-icon">
            {children}
            <style jsx>{`
                .jlt_svg-icon {
                    ${!isEmpty(parentStyles) ? `${JSToCSS(parentStyles)}` : ""};
                    svg {
                        ${!isEmpty(styles) ? `${JSToCSS(styles)}` : ""};
                        > path {
                            ${type == "stroke" ? `stroke: ${color};` : ""}
                            ${type == "fill" ? `fill: ${color};` : ""}
                        }
                    }
                }
            `}</style>
        </span>
    );
}

export default StyledIcon;

// const SVGIcon = styled.span.attrs((props) => ({}))`
//     > svg {
//         ${(props) => (!isEmpty(props.$sty) ? props.$sty : "")};
//         > path {
//             ${(props) => (props.$type == "stroke" ? `stroke: ${props.$color}` : "")}
//             ${(props) => (props.$type == "fill" ? `fill: ${props.$color}` : "")}
//         }
//     }
// `;
